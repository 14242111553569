/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

 import React from "react"
 import PropTypes from "prop-types"
 import { Helmet } from "react-helmet"
 import { ACTION_TYPES, getPriceStr, toTitleCase } from "./utils"
 import { useLocation } from "@reach/router"


 import _ from "lodash"
 export { ACTION_TYPES, ACTION_DESC_TYPES, getPriceStr } from "./utils";

 const SeoResults = ({ description, lang, meta, title, searchParams, location, pcategorytype, offplan }) => {
   const {areas, pType, type, price, commType, mustInclude } = searchParams;
   //console.log("mustInclude", mustInclude)
   var replace_url = pcategorytype+"_"+pType;

   const actionStr = ACTION_TYPES[replace_url];
   const priceStr = getPriceStr(price)
   const bedrooms = searchParams.bedroom?.min
   const pricerange = searchParams.price
   const searcharea = areas
   const capitalize = (s) => {
     if (typeof s !== 'string') return ''
     return s.charAt(0).toUpperCase() + s.slice(1)
   }

   let propertyType = ""

   if(type == "") {
     propertyType = "properties"
  } else {
     propertyType = type+"s".replace(/-/g, ' ').toLowerCase();
   }
   let desc = "";
   if(offplan) {
    if(searcharea === "dubai") {
      desc="Explore our latest collection of off plan properties "+ actionStr + toTitleCase(areas.replace(/-/g, ' ')) +". Contact Arabian Estates Properties in "+toTitleCase(areas.replace(/-/g, ' '))+" to find the right Properties for you.";
   }else {
     // desc="Explore our range of off plan properties "+actionStr+ toTitleCase(areas.replace(/-/g, ' ')) +". Contact Arabian Estates properties to find the right properties "+actionStr+ toTitleCase(areas.replace(/-/g, ' '));
     desc="Looking for the best off plan property in "+toTitleCase(areas.replace(/-/g, ' ')) +" to invest? Explore off-plan properties"+actionStr+ toTitleCase(areas.replace(/-/g, ' ')) +" with Arabian Estates. Your gateway to new developments in "+ toTitleCase(areas.replace(/-/g, ' ')) +".";
   }
   if(propertyType !="properties") {
     desc="Looking for an off plan "+propertyType.replace(/-/g, ' ')+ actionStr + toTitleCase(areas.replace(/-/g, ' '))+"? Arabian Estates properties are here to help you in your properties journey.";
   }
   if(bedrooms) {
    desc="Explore off plan properties "+ actionStr + toTitleCase(areas.replace(/-/g, ' ')) +" with more than "+searchParams.bedrooms+" bedrooms here. Contact our expert real estate brokers today to get assistance in finding the right properties in "+toTitleCase(areas.replace(/-/g, ' '));
   }

   if(pricerange.min || pricerange.max) {
       desc="Locate the right off plan properties "+ actionStr + toTitleCase(areas.replace(/-/g, ' ')) +priceStr+" with Arabian Estates properties. Book an appointment with one of our properties experts in "+toTitleCase(areas.replace(/-/g, ' '));
   }
   if (propertyType !="properties" && (searchParams.price.min || searchParams.price.max)) {
      desc= "Check out the latest collection of off plan "+propertyType.replace(/-/g, ' ')+ actionStr + priceStr +" in "+ toTitleCase(areas.replace(/-/g, ' '))+ priceStr+" with Arabian Estates properties in "+toTitleCase(areas.replace(/-/g, ' '));
   }
   if((pricerange.min || pricerange.max) && bedrooms) {
    desc="Explore the list of off plan properties "+ actionStr+"  in "+toTitleCase(areas.replace(/-/g, ' '))+" with more than "+searchParams.bedrooms+" bedrooms "+priceStr+" with Arabian Estates properties and request a viewing for the properties that interests you.";
   }
   if (propertyType !="properties" && bedrooms) {
     desc="Find the latest collection of off plan "+propertyType.replace(/-/g, ' ')+ actionStr + toTitleCase(areas.replace(/-/g, ' '))+" with more than "+searchParams.bedrooms+" bedrooms. Contact Arabian Estates properties in "+toTitleCase(areas.replace(/-/g, ' '))+", to arrange a viewing.";
   }
   if(propertyType !="properties" && (pricerange.min || pricerange.max) && bedrooms) {

     desc=""+propertyType.replace(/-/g, ' ')+ actionStr + toTitleCase(areas.replace(/-/g, ' '))+" with more than "+searchParams.bedrooms+" bedrooms "+priceStr+" available through Arabian Estates properties. Contact one of our real estate brokers for assistance in finding your properties in "+toTitleCase(areas.replace(/-/g, ' '))+".";
   }
   }
   else {
    if(searcharea === "dubai") {
      desc="Explore our latest collection of properties "+ actionStr + toTitleCase(areas.replace(/-/g, ' ')) +". Contact Arabian Estates Properties in "+toTitleCase(areas.replace(/-/g, ' '))+" to find the right Properties for you.";
   }else {
     desc="Explore our range of properties "+actionStr+ toTitleCase(areas.replace(/-/g, ' ')) +". Contact Arabian Estates properties to find the right properties "+actionStr+ toTitleCase(areas.replace(/-/g, ' '));
   }
   if(propertyType !="properties") {
     desc="Looking for a "+propertyType.replace(/-/g, ' ')+ actionStr + toTitleCase(areas.replace(/-/g, ' '))+"? Arabian Estates properties are here to help you in your properties journey.";
   }
   if(bedrooms) {
    desc="Explore properties "+ actionStr + toTitleCase(areas.replace(/-/g, ' ')) +" with more than "+searchParams.bedrooms+" bedrooms here. Contact our expert real estate brokers today to get assistance in finding the right properties in "+toTitleCase(areas.replace(/-/g, ' '));
   }

   if(pricerange.min || pricerange.max) {
       desc="Locate the right properties "+ actionStr + toTitleCase(areas.replace(/-/g, ' ')) +priceStr+" with Arabian Estates properties. Book an appointment with one of our properties experts in "+toTitleCase(areas.replace(/-/g, ' '));
   }
   if (propertyType !="properties" && (searchParams.price.min || searchParams.price.max)) {
      desc= "Check out the latest collection of "+propertyType.replace(/-/g, ' ')+ actionStr + priceStr +" in "+ toTitleCase(areas.replace(/-/g, ' '))+ priceStr+" with Arabian Estates properties in "+toTitleCase(areas.replace(/-/g, ' '));
   }
   if((pricerange.min || pricerange.max) && bedrooms) {
    desc="Explore the list of properties "+ actionStr+"  in "+toTitleCase(areas.replace(/-/g, ' '))+" with more than "+searchParams.bedrooms+" bedrooms "+priceStr+" with Arabian Estates properties and request a viewing for the properties that interests you.";
   }
   if (propertyType !="properties" && bedrooms) {
     desc="Find the latest collection of "+propertyType.replace(/-/g, ' ')+ actionStr + toTitleCase(areas.replace(/-/g, ' '))+" with more than "+searchParams.bedrooms+" bedrooms. Contact Arabian Estates properties in "+toTitleCase(areas.replace(/-/g, ' '))+", to arrange a viewing.";
   }
   if(propertyType !="properties" && (pricerange.min || pricerange.max) && bedrooms) {

     desc=""+propertyType.replace(/-/g, ' ')+ actionStr + toTitleCase(areas.replace(/-/g, ' '))+" with more than "+searchParams.bedrooms+" bedrooms "+priceStr+" available through Arabian Estates properties. Contact one of our real estate brokers for assistance in finding your properties in "+toTitleCase(areas.replace(/-/g, ' '))+".";
   }
   }


//    else {
//      // price
//      if (price.min || price.max){
//        desc = propertyType + actionStr + areas + "."
//      }
//    }

     //
     let titlePropertyType = ""

     if(type == "") {
         titlePropertyType = "properties"
     } else {
         titlePropertyType =  capitalize(type+"s".replace(/-/g, ' '))
     }

     let bedroomType = ""

     if ( bedrooms ) {
      bedroomType = ' with more than '+bedrooms+' bedrooms'
    }
    let Amenities = ""
    if ( mustInclude && mustInclude ) {
      var includecap = mustInclude.map( a => a.charAt(0).toUpperCase() + a.substr(1) );
      Amenities = " with "+capitalize(includecap.join(' and '))
    }
     let descTitle = toTitleCase(titlePropertyType.replace(/-/g, ' ')) + actionStr + toTitleCase(areas.replace(/-/g, ' ')) + bedroomType + priceStr + Amenities.replace(/-/g, ' ')
     //

      // console.log("Props Desc => ", desc);

      description = desc

      // console.log("Props Desc => ", desc);
      var { pathname } = useLocation()

      if (pathname == "/home/" || pathname == "/home" )
        pathname = "/"
      if (pathname == "/property/for-sale" || pathname == "property/for-sale" )
        pathname = "/property/for-sale/in-dubai/"
      if (pathname == "/property/to-rent/" || pathname == "property/to-rent" )
        pathname = "/property/to-rent/in-dubai/"

      var curentUrl = '';
      curentUrl = process.env.GATSBY_SITE_URL+pathname;

      // set no index for result pagination
      let hasPagination = false;
      if (pathname !== undefined && pathname) {
        let pageStr = pathname.split('page-');
        hasPagination = pageStr[1] || false;
        if (pageStr[0] !== undefined) {
         curentUrl = process.env.GATSBY_SITE_URL + pageStr[0];
        }
      }

      if (!curentUrl.endsWith('/'))
            curentUrl = curentUrl + '/';

      //remove multiple slashes
      curentUrl = curentUrl.replace(/([^:]\/)\/+/g, "$1");
      //set lowercase url
      curentUrl = curentUrl.toLowerCase();

      var canonicalURL = curentUrl
      /*if(pathname.includes('property/for-sale')) {
        canonicalURL = process.env.GATSBY_SITE_URL+'/property/for-sale/';
      }
      if(pathname.includes('property/to-rent')) {
        canonicalURL = process.env.GATSBY_SITE_URL+'/property/to-rent/in-dubai/';
      }*/


   let metaItems = [
     {
       name: `description`,
       content: description.replace(/And/g, "and"),
     },
     {
       property: `og:title`,
       content: title.replace(/And/g, "and"),
     },
     {
       property: `og:description`,
       content: description.replace(/And/g, "and"),
     },
     {
       property: `og:type`,
       content: `website`,
     },
     {
      name: `og:url`,
      content: curentUrl,
    },

     {
       name: `twitter:card`,
       content: `summary`,
     },
     {
       name: `twitter:creator`,
       content: `Arabian Estates`,
     },
     {
       name: `twitter:title`,
       content: title.replace(/And/g, "and"),
     },
     {
       name: `twitter:description`,
       content: description.replace(/And/g, "and"),
     },
   ].concat(meta);


   // set no index for result pagination
  //  let hasPagination = false;
  //  if (location !== undefined && location.pathname) {
  //    let pageStr = location.pathname.split('page-');
  //    hasPagination = pageStr[1] || false;
  //  }
  //  if ( hasPagination ) {
  //    metaItems.push({
  //      name: `robots`,
  //      content: `noindex`,
  //    })
  //  }

   return (
     <Helmet
       htmlAttributes={{
         lang,
       }}
       link={[
        {
          href: canonicalURL,
          rel: "canonical"
       }]}
       title={title.replace(/And/g, "and")}
       titleTemplate={`%s | Arabian Estates`}
       meta={metaItems}
     />
   )
 }

 SeoResults.defaultProps = {
   lang: `en`,
   meta: [],
   description: ``,
   title: ``,
   searchParams: [],
 }

 SeoResults.propTypes = {
   description: PropTypes.string,
   lang: PropTypes.string,
   meta: PropTypes.arrayOf(PropTypes.object),
   title: PropTypes.string.isRequired,
   searchParams: PropTypes.any,
 }

 export default SeoResults
